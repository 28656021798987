<template>
  <div class="card mb-12">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <h1 class="card-label">Account Verification Details</h1>
      </div>
    </div>
    <div :class="tableData.length ? 'py-0' : 'pt-0'" class="card-body">
      <Datatable
        :table-header="tableHeader"
        :table-data="tableData"
        :enable-items-per-page-dropdown="false"
        :loading="loading"
      >
        <template v-slot:cell-kyc="{ row }">
          <div
            class="overlay cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#modal"
            @click="changeImage(row.image)"
          >
            <div class="symbol symbol-150px">
              <img :src="row.image" alt="Picture" />
              <div class="card-img overlay-layer">
                <span class="material-icons-outlined text-white">
                  image_search
                </span>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:cell-title="{ row }">
          {{ row.title }}
        </template>
        <template v-slot:cell-details="{ row }">
          <div :key="i" v-for="(detail, i) in row.details">
            {{ detail }}
          </div>
        </template>
        <template v-slot:cell-status="{ row }">
          <span
            class="badge px-8 py-3 fs-5"
            :class="
              row.verifyState === 'RejectVerify'
                ? 'badge-light-danger'
                : row.verifyState === 'WaitReviewVerify'
                ? 'badge-light-primary text-w-sky'
                : 'badge-light-success text-w-emerald px-9'
            "
            >{{
              row.verifyState === 'RejectVerify'
                ? 'Rejected'
                : row.verifyState === 'WaitReviewVerify'
                ? 'Pending'
                : 'Verified'
            }}</span
          >
        </template>
        <template v-slot:cell-action="{ row, index }">
          <div style="min-width: 150px">
            <input
              type="radio"
              class="btn-check"
              :name="`btn-radio-${index}`"
              :id="`btn-radio-1-${index}`"
              v-model="tableData[index].verifyState"
              value="Done"
            />
            <label
              style="padding-right: 1.85rem"
              :style="row.verifyState === 'Done' && 'cursor: default'"
              class="btn btn-light-success"
              :for="`btn-radio-1-${index}`"
              @click="
                row.verifyState !== 'Done' && verifyDocument('verify', row._id)
              "
            >
              <span class="material-icons-outlined fs-3 me-2 align-middle">
                check_circle
              </span>
              Verify
            </label>
          </div>

          <div class="mt-6" style="min-width: 150px">
            <input
              type="radio"
              class="btn-check"
              :name="`btn-radio-${index}`"
              :id="`btn-radio-2-${index}`"
              v-model="tableData[index].verifyState"
              value="RejectVerify"
            />
            <label
              :style="row.verifyState === 'RejectVerify' && 'cursor: default'"
              class="btn btn-light-danger"
              :for="`btn-radio-2-${index}`"
              @click="
                row.verifyState !== 'RejectVerify' &&
                  verifyDocument('reject', row._id)
              "
            >
              <span class="material-icons-outlined fs-3 me-2 align-middle">
                block
              </span>
              Reject
            </label>
          </div>
        </template>
      </Datatable>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" id="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-transparent shadow-none">
        <img
          class="figure-img img-fluid rounded m-0"
          :src="currentImage"
          alt="Image"
        />
      </div>
    </div>
  </div>

  <RejectForm
    :disabled="!tableData.some((item) => item.verifyState === 'RejectVerify')"
    v-model="rejectFormRef"
  ></RejectForm>

  <div class="d-flex justify-content-end">
    <button
      name="confirm verification"
      class="btn btn-primary py-4 px-20 fs-1 text-bold"
      :class="
        !tableData.some((item) => item.verifyState === 'RejectVerify')
          ? 'btn-primary'
          : 'btn-danger'
      "
      :disabled="
        !tableData.every((item) => item.verifyState !== 'WaitReviewVerify')
      "
      data-bs-toggle="modal"
      data-bs-target="#confirm"
    >
      {{
        !tableData.some((item) => item.verifyState === 'RejectVerify')
          ? 'Approve'
          : 'Reject'
      }}
    </button>
  </div>

  <ConfirmModal ref="modalRef">
    <button
      type="button"
      name="confirm"
      class="btn btn-primary px-10"
      @click="
        !tableData.some((item) => item.verifyState === 'RejectVerify')
          ? verifyPartner('approve')
          : verifyPartner('reject')
      "
    >
      Confirm
    </button>
  </ConfirmModal>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import RejectForm from '@/components/cards/RejectForm.vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export default {
  name: 'PartnerVerifyWildcard',
  components: { Datatable, ConfirmModal, RejectForm },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { id } = route.params;
    const { role } = route.query;
    const tableHeader = [
      {
        name: 'KYC Attachment',
        key: 'kyc',
        sortable: false,
      },
      {
        name: 'Label',
        key: 'title',
        sortable: true,
      },
      {
        name: 'Details',
        key: 'details',
        sortable: false,
      },
      {
        name: 'Status',
        key: 'status',
        sortable: true,
      },
      {
        name: 'Action',
        key: 'action',
        sortable: false,
      },
    ];
    const tableData = ref([]);
    const loading = ref(true);
    const currentImage = ref('');
    const data = ref({});
    const modalRef = ref(null);
    const rejectFormRef = ref({ textarea: '', checkboxes: [] });

    onMounted(async () => {
      const url =
        role === 'Driver' || role === 'Staff' ? 'partner' : 'organization';
      const res = await ApiService.get(`${url}/${id}`);
      const details = res.data.data;

      document.title = data.value.name || 'Verify Customer';

      data.value =
        role === 'Driver' || role === 'Staff'
          ? details[role.toLowerCase()]
          : details;
      tableData.value =
        details.verifyDocuments || details[role.toLowerCase()].verifyDocuments;

      tableData.value.forEach((row) => {
        switch (row.title) {
          case 'รูปถ่ายหน้าคุณพร้อมบัตรประชาชน':
          case 'รูปถ่ายบัตรประชาชน':
            row.details = [
              details.name,
              `หมายเลขบัตรประชาชน ${data.value.personalId}`,
            ];
            break;
          case 'รูปถ่ายใบขับขี่':
            row.details = [
              details.name,
              `หมายเลขใบขับขี่ ${data.value.driverId}`,
              `วันหมดอายุใบขับขี่ ${data.value.driverIdExp}`,
            ];
            break;
          case 'สำเนาบุ้คแบงค์สำหรับโอนเงินเข้า':
            row.details = [`หมายเลขบัญชี ${data.value.personalBackId}`];
            break;
          case 'หนังสือประชุมของทางบริษัท':
          case 'สำเนาหนังสือรับรองบริษัท':
            row.details = [data.value.name];
            break;
          case 'หนังสือมอบอำนาจ':
          case 'สำเนาบัตรประชาชนกรรมการผู้มีอำนาจลงนาม':
            row.details = [data.value.directorName];
            break;
          case 'สำเนาบุ้คแบงค์':
            row.details = [`หมายเลขบัญชี ${data.value.bankAccountNo}`];
            break;
          default:
            row.details = ['-'];
            break;
        }
      });

      // if (role !== 'Driver' || role !== 'Staff') {
      //   const { data } = await ApiService.get('partner');
      //   const ownerAccount = data.datas.find(
      //     (partner) => partner.organization.id === id
      //   );
      // }

      setCurrentPageBreadcrumbs(
        'Partner Account',
        [
          { name: 'Account Verification', path: '/partner-account/verify' },
          'Account Examination',
        ],
        { name: 'View Details', path: `/partner-account/lists/${id}` },
        false
      );

      loading.value = false;
    });

    const changeImage = (image) => {
      currentImage.value = image;
    };

    const verifyDocument = async (state, documentID) => {
      loading.value = true;

      const query =
        role === 'Driver' || role === 'Staff'
          ? `${state}-document`
          : `${state}-organization-document`;

      await ApiService.post(`partner/${id}/${query}/${documentID}`, {
        type: role.toLowerCase(),
      });

      loading.value = false;
    };

    const verifyPartner = async (state) => {
      loading.value = true;
      hideModal(modalRef.value.$el);

      const query =
        role === 'Driver' || role === 'Staff' ? state : `${state}-organization`;

      let rejectReasons = '';

      if (rejectFormRef.value.checkboxes.length) {
        if (rejectFormRef.value.textarea) {
          rejectReasons =
            rejectFormRef.value.checkboxes.join(', ') +
            ', ' +
            rejectFormRef.value.textarea;
        } else {
          rejectReasons = rejectFormRef.value.checkboxes.join(', ');
        }
      } else {
        rejectReasons = rejectFormRef.value.textarea;
      }

      const body =
        state === 'reject'
          ? {
              type: role.toLowerCase(),
              rejectVerifyReason: rejectReasons,
            }
          : { type: role.toLowerCase() };

      await ApiService.post(`partner/${id}/${query}`, body);

      loading.value = false;

      const pastState = state === 'approve' ? 'approved' : 'rejected';

      await Swal.fire({
        text: `Partner has been successfully ${pastState}`,
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Back to partner lists',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });

      router.push({ name: 'Partner Account Verification Lists' });
    };

    return {
      tableHeader,
      tableData,
      loading,
      currentImage,
      changeImage,
      data,
      verifyDocument,
      verifyPartner,
      modalRef,
      rejectFormRef,
    };
  },
};
</script>

<style scoped lang="sass">
.material-icons-outlined.text-white
  font-size: 10rem

.overlay-layer
  background-color: #8D8E96
</style>
